import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { setSnackbar } from 'redux/features/ui-slice';
import axios, { API } from 'lib/config/axios';
import PATHS from 'lib/navigation/paths';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';
import useCookies from 'views/common/hooks/useCookies';

import TourPackageSelector from 'views/tours-module/TourView/components/TourPackageSelector';
import useHandleNavigationToRPPage from 'views/common/hooks/useHandleNavigationToRPPage';
import { WL_SS_KEY } from 'views/common/components/Logical/MainWidgetWrapper/utils/constants';

const ReservationWrapper = (props) => {
  const {
    bookingDetails,
    tourId,
    startingPrice,
    includeBike,
    onIncludeBikeChange,
    getBookingDetails,
    acceptableReservationsType,
    handleShowActionBar,
    tourType,
  } = props;

  const { appState, isInIframe } = useSelector((state) => state.globalData);
  const dispatch = useDispatch();
  const handleErrorResponse = useErrorsHandler();
  const appNavigator = useAppNavigator();
  const { navigateToNextPage } = useHandleNavigationToRPPage();

  const [, setCookie, removeCookie] = useCookies();

  const [bookingError, setBookingError] = useState();

  const getFilteredStartingPrice = () => (includeBike
    ? startingPrice.with_motorcycle
    : startingPrice.without_motorcycle);

  const disableDays = (date) => {
    const blackoutIntervals = bookingDetails.calendar_options.blackout_intervals;
    const disabledWeekDays = bookingDetails.calendar_options.disabled_week_days;

    let found;
    if (blackoutIntervals) {
      found = blackoutIntervals.findIndex(
        (interval) => date >= dayjs(interval.start) && date <= dayjs(interval.end),
      );
      if (found != -1) return true;
    }

    if (disabledWeekDays) {
      found = disabledWeekDays.findIndex(
        (interval) => interval.days.findIndex((day) => day == dayjs(date).get('d')) != -1
          && date >= dayjs(interval.start)
          && date <= dayjs(interval.end),
      );
      if (found != -1) return true;
    }

    return false;
  };
  const handleErrorReset = () => {
    setBookingError(null);
  };

  const handleGetBookingDetails = (widgetData) => {
    getBookingDetails(tourId, widgetData);
    handleErrorReset();
  };

  const getReferralCode = () => appNavigator.query.referral_code
    || appNavigator.query['reservation[referral_code]']
    || sessionStorage.getItem(WL_SS_KEY);

  const bookTour = (widgetData) => {
    axios
      .post(API.tours.bookTour.replace('{tour_id}', tourId), {
        authenticity_token: appState.authenticity_token,
        referral_code: getReferralCode(),
        ...widgetData,
      })
      .then((res) => {
        const result = res.data;
        removeCookie('reservation');
        const cookieParams = { path: '/' };
        const cookieName = 'reservation';
        const cookieValue = result.reservation.identifier;
        if (isInIframe) {
          setCookie(cookieName, cookieValue, {
            ...cookieParams,
            sameSite: 'none',
            secure: true,
          });
        } else {
          setCookie(cookieName, cookieValue, cookieParams);
        }

        if (includeBike) {
          navigateToNextPage(res.data.reservation);
        } else {
          appNavigator.push(
            PATHS.personalBikeReservation.checkout(
              result.reservation.identifier,
            ),
          );
        }
      })
      .catch((error) => {
        switch (error.response.status) {
          case 406:
            switch (error.response.data.error_code) {
              case 'ER0001':
                setBookingError(error.response.data);
                break;
              case 'ER0002':
                dispatch(
                  setSnackbar({
                    open: true,
                    severity: 'error',
                    message: error.response.data.error_message,
                  }),
                );
                break;
              default:
                handleErrorResponse(error);
            }
            break;
          case 403:
            dispatch(
              setSnackbar({
                open: true,
                severity: 'error',
                message: error.response.data.message,
              }),
            );
            break;
          default:
            handleErrorResponse(error, false);
        }
      });
  };

  return (
    <>
      {bookingDetails && includeBike !== undefined ? (
        <TourPackageSelector
          bookingDetails={bookingDetails}
          includeBike={includeBike}
          startingPrice={getFilteredStartingPrice()}
          onIncludeBikeChange={onIncludeBikeChange}
          onBookTour={bookTour}
          bookingError={bookingError}
          disableDates={disableDays}
          getBookingDetails={handleGetBookingDetails}
          acceptableReservationsType={acceptableReservationsType}
          handleShowActionBar={handleShowActionBar}
          tourType={tourType}
          handleErrorReset={handleErrorReset}
        />
      ) : (
        includeBike !== undefined && (
          <TourPackageSelector
            includeBike={includeBike}
            onIncludeBikeChange={onIncludeBikeChange}
            bookingError={bookingError}
            onBookTour={bookTour}
            acceptableReservationsType={acceptableReservationsType}
            getBookingDetails={handleGetBookingDetails}
            handleShowActionBar={handleShowActionBar}
            tourType={tourType}
            handleErrorReset={handleErrorReset}
          />
        )
      )}
    </>
  );
};

export default ReservationWrapper;
